@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts/ProximaNova-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts/ProximaNova-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}


.bg-background{
  @apply bg-[#0F0F0F]
}

body {
  @apply bg-background text-white font-proxima
}
.container {
  @apply w-full sm:w-[624px] md:w-[744px] lg:w-[940px] xl:w-[1150px]
}

.pre-container {
  @apply flex justify-center
}

.main-screen {
    @apply min-h-screen grow flex flex-col
}

.header{
  @apply h-[60px] sticky top-0 z-50 bg-background
}
.bg-primary {
  @apply !bg-[#FF2E2E]
}
.text-primary {
  @apply !text-[#FF2E2E]
}
.border-primary {
  @apply !border-[#FF2E2E]
}
.bottom-banner{
  background-image: url('./assets/images/bottom-banner-wide.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.overlay-bottom-banner:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
}

.my-btn{
  @apply duration-300 hover:scale-110
}
.hover\:bg-primary {
  @apply hover:bg-[#FF2E2E]
}

.animate-check-mark {
    scale: 100%;
    animation: check-mark 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes check-mark {
    0% {
        transform: scale(0%);
    }
    90% {
        transform: scale(110%);
    }
    100% {
        transform: scale(100%);
    }
}
/* Add the following CSS rules */
.hover\:underline {
  text-decoration: underline;
  text-decoration-color: red;
}

.hover\:text-red {
  color: white;
}

.hover\:text-red:hover {
  color: #E5313A;
}
::-webkit-scrollbar-track
{
    background-color: #0F0F0F;
}

::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    background-color: #E5313A;
}
svg {
    transform: unset !important;
}
/* CSS */
.hover-effect {
  transition: transform 0.3s ease; /* Dodajanje prehoda za gladko animacijo */
}

.hover-effect:hover {
  transform: scale(1.2); /* Povečaj velikost slike ob hoverju */
}
